<template>
    <section class="content-container" id="CommunicationsEngagement" name='Communication & Engagement'>
        <div class="titles">
            <h4>Communications and Engagement</h4>
        </div>

        <div class="col-12 secondary-image-container">
            <img src="@/assets/covid/diagram_23_1.png" alt="">
            <p class="caption">Dr Koh Poh Koon, Senior Minister of State for Health, at a webinar AIC conducted to engage with GPs</p>        
        </div>

        <div class="col-12 px-3">
            <p>As with the Community Care sector, AIC served as a communications node between MOH and primary care partners, facilitating regular engagement sessions between GPs and MOH. AIC also facilitated several COVID-19 focus group discussions and situation update sessions for the GPs. 


            <br><br>
            In February 2020, MOH, the Government Technology Agency of Singapore and AIC launched <a href='https://flu.gowhere.gov.sg'>flu.gowhere.gov.sg</a>. The website enables the public to find nearby PHPCs and SASH clinics islandwide. We also enhanced our <a href='https://www.primarycarepages.sg/'>Primary Care Pages</a> website in April 2020 with a dedicated COVID-19 operations page to provide GPs access to timely updates on policy and operations, as well as resources and reference materials.
            </p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'CommunicationsEngagement',
}
</script>
