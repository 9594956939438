<template>
	<section class="content-container" id="CovidReadinessAssessment" name='Covid Readiness Assessment'>
        <div class="titles">
            <h5>COVID-19 Readiness Assessment </h5>
        </div>
        
        <div class="col-12 secondary-image-container">
              <img src="@/assets/covid/diagram_13_1.png" alt="">
                <p class="caption">A staff member at All Saints Silver Lifestyle Club @ Yishun Fern Grove sanitising vehicles used to ferry clients, in line with CRA requirements</p>  
        </div>

        <div class="col-12 px-3">
            <p>As Community Care organisations resumed their services after the Circuit Breaker, they were required to follow MOH safe management guidelines to protect staff and clients. To promote and facilitate partners’ self-assessment, AIC developed COVID-19 Readiness Assessments (CRA) on the implementation of safe management measures. Together with MOH, we also conducted onsite assessments to engage partners and share good practices.</p>
        </div>
        <div class="col-12">
            <div class="table-diagram">
                <div class="row">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_13.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                         <p>Onsite assessments were conducted at over  <span class="stats">130</span> Community Care facilities.</p>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
export default {
    name: 'CovidReadinessAssessment',
}
</script>
