<template>
    <!-- This is the mobile version !-->
    <section class="sub-menu d-block row justify-content-center fixed-top" id="sub-menu">
        <div class=" text-left w-100" id="accordion2">
            <div class="">
                <!-- Mobile Only Level 1 Menu !-->
                <div class="pl-5 pr-4" id="subnav-1">
                    <p class="collapsed link lv-0" tabindex="1" role="button" v-b-toggle.subnav-collapse-1>
                        Sustaining Community Care M-top<span class="icon"><font-awesome-icon :icon="['fas', 'caret-down']" class="mt-1"/></span>
                    </p>
                </div>
                
                <b-collapse id="subnav-collapse-1" class="pl-5 pr-4 subnav-content" data-parent="#accordion2" aria-labelledby="subnav-1" v-model="subnav_1">
                    <div class="d-block w-100">
                        <div id="accordion2-1">
                            <div class="pt-2">
                                <div id="subnav-1-1">
                                    <p class="position-relative collapsed link lv-1" tabindex="1" role="button" v-b-toggle.subnav-collapse-1-1>
                                        Sustaining Community Care 
                                        <span class="icon"><font-awesome-icon :icon="['fas', 'minus']" class="mt-1"/></span>
                                    </p>
                                </div>

                                <b-collapse id="subnav-collapse-1-1" accordion="subnav-1-1" v-model="subnav_1_1">
                                    <ul class='menu-2'>                                        
                                        <li><router-link class="link-2" to="#CommunityCare"> 
                                            Supporting Business Continuity
                                            </router-link>
                                            <ul class='menu-3'>
                                             <li v-for='item in subnav_2[0]'>
                                                    <router-link class="link-3" :to="'#' + item.hash"> 
                                                        {{ item.name }}
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li><router-link class="link-2" to="#Facilitating">Facilitating Testing & Vaccination</router-link></li>
                                    </ul>
                                </b-collapse>
                            </div>


                            <div class="pt-2">
                                <div id="subnav-1-2">
                                    <p class="position-relative collapsed link lv-1" tabindex="1" role="button" v-b-toggle.subnav-collapse-1-2>
                                        Bolstering Primary Care <span class="icon"><font-awesome-icon :icon="['fas', 'minus']" class="mt-1"/></span>
                                    </p>
                                </div>
                                <b-collapse id="subnav-collapse-1-2" accordion="subnav-1-1">
                                    <ul>
                                        <li class="ml-4 mb-2 mt-1"><router-link class="link-2" to="#BolsteringPrimaryCare">Public Health Preparedness Clinics(PHPC)</router-link></li>
                                        <li class="ml-4 mb-2 mt-1"><router-link class="link-2" to="#DetectingIsolating">Supporting Community Testing & Vaccination</router-link></li>
                                        <li class="ml-4 mt-1"><router-link class="link-2" to="#CommunicationsEngagement">Communications & Engagement</router-link></li>
                                    </ul>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>
    </section>  
</template>

<script>

var level_3 = {
    "0" : {
      "name": "Sector Communications",
      "hash": "CommunityCare"
    },
    "1": {
      "name": "Infection Control & Prevention",
      "hash": "InfectionControlPrevention"
    },
    "2": {
      "name": "Donations & Sponsorships",
      "hash": "DonationsSponsorships"
    },
    "3": {
      "name": "Going Digital",
      "hash": "GoingDigital"
    },
    "4": {
      "name": "Nursing home staff accomdation",
      "hash": "NursingStaff"
    },
    "5": {
      "name": "COVID-19 Readiness Assessment",
      "hash": "CovidReadinessAssessment"
    },
    "6": {
      "name": "COVID-19 Incident Response Team (CIRT)",
      "hash": "Cirt"
    }
};


export default {
    name: 'Subnavigation',
    data(){
        return {
            subnav_1: false,
            subnav_1_1: false,

            subnav_2: {
                  "0": level_3
            }
        }
    },
    mounted(){
        window.addEventListener("resize", this.onResizeWindow);
        window.addEventListener("scroll", this.fixedSubMenu);        
        this.onResizeWindow();
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResizeWindow);
        window.removeEventListener("scroll", this.fixedSubMenu);
    },
    methods: {
        onResizeWindow: function(){
            if(window.innerWidth>=768){
                this.subnav_1 = true;
                this.subnav_1_1 = true;
            }else{
                this.subnav_1 = false;                
                this.subnav_1_1 = false;
            }
            this.fixedSubMenu();
        }, 
        fixedSubMenu: function(e){
            let content = document.getElementById('content-covid');
            let el = document.getElementById('sub-menu');
            let mainNav = document.getElementById('main-navigation');

            if(window.innerWidth>=768 && window.scrollY> content.offsetTop-80){
                mainNav.style.position="";
                el.style.position = "fixed";
                el.style.top = "0";
            }else if(window.innerWidth>=320 && window.innerWidth<768) {
                mainNav.style.position="fixed";
            }else{
                el.style.position = "";                
                el.style.top = "";
            }
        }
    }
}
</script>

<style>
    .sub-menu{
        z-index: 1;
    }
    #subnav-collapse-1{
        position: absolute;
        width: 100%;
        padding-bottom: 10px;
        line-height:20px;
    }
    .menu-2 li {
        margin:  10px 0px 10px 25px;
    }

    .menu-3 li{
        margin:  10px 0px 10px 25px;
    }

    @media(min-width: 320px){
        /* menu-for mobile */
        .sub-menu{
            background-color: rgba(75, 75, 75, 1);
            line-height: 40px;
            position: fixed;
            width: 100%;
            top: 45px;
        }
        .sub-menu .icon{
            float: right;
        }

        .subnav-content {
            background-color: #808080;
        }    
        #accordion-1> *, .left-menu, #accordion2-1 > * {
            border-bottom: 1px solid #ecebde;
        }

        .link-2 {
            font-size: 12px;
        }

        .link-3 {
            font-size: 12px;
        }

        .lv-0, .lv-2, .link-2 {
            color: #ffc107;
        }

        .lv-1 {
            color: #FFF;
            padding: 5px 0;
        }
    }

    @media(min-width: 768px){
        #subnav-1{
            display:none;
        }

        .menu-3 li {
            padding:  2px;
        }
        .menu-3 .link-3 {
            color: #808080;  
        }

        .subnav-content, .sub-menu {
            background-color: transparent;
        }    
        .sub-menu{
            width: 30vw;
            position: relative;
            padding-top:10px;
            top:0;
        }
        .lv-0, .lv-1, .link-2{
            color: #363636;
        }

        .link-2, .link-3 {
            font-size: 16px;
        }
        .link-2:hover, .link-3:hover{
            color: #da3305;
        }
    }
</style>
