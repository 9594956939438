<template>
    <section class="content-container" id="InfectionControlPrevention" name='Infection and Control Prevention'>
        <div class="titles">
            <h5>Infection Control & Prevention (IPC)</h5>
        </div>
        <div class="col-12 px-3">
            <p>To prevent the spread of COVID-19, we reviewed and refreshed IPC practices, competencies and training in the use of appropriate personal protective equipment (PPE). We also conducted workshops, as well as developed guides and resources on IPC measures. 

                <br><br>
            As a precautionary measure, Community Care staff were required to wear appropriate PPE when providing care. These include surgical and N95 masks, gloves, gowns and face shields. As our partners kept only small PPE stocks for emergencies, AIC liaised with MOH to tap into the national stockpile to keep them supplied. We coordinated the fortnightly PPE distribution to the sector, and organised additional mask-fitter workshops and sessions.</p>
        </div>
        <div class="col-12">
            <div class="table-diagram">
                <div class="row">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_3.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                         <p>Since February 2020, AIC has distributed close to <span class="stats">29 million</span> PPE kits to over <span class="stats">140</span> Community Care organisations.</p>
                    </div>
                </div>
            </div>
        </div>


    </section>
</template>

<script>
export default {
    name: 'InfectionControlPrevention',
}
</script>
