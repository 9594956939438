<template>
	<section class="content-container" id="Cirt" name='CIRT'>
        <div class="titles">
            <h5>COVID-19 Incident Response Team (CIRT)</h5>
        </div>

        <div class="col-12 secondary-image-container">
            <img src="@/assets/covid/diagram_15_1.png" alt="">
            <p class="caption">CIRT supporting a nursing home during its COVID-19 incident</p>
               
        </div>

        <div class="col-12 px-3">
            <p>When COVID-19 cases occurred at a Community Care facility, AIC set up CIRT to coordinate with the service provider, public health and infectious disease experts, and hospital and laboratory services. We were supported by partners from the public sector and healthcare institutions who stepped forward readily to contribute their expertise. </p>
        </div>
        <div class="col-12">
            <div class="table-diagram">
                <div class="row">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_14.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                         <p>CIRT supported <span class="stats">16</span> COVID-19 <br> incidents in the Community Care sector.</p>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
export default {
    name: 'Cirt',
}
</script>
