<template>
    <div>
    <section class="content-container" id="PHPCs" name='Resources for PHPCs'>
        <div class="titles">
            <h4>Resources for PHPCs</h4>
        </div>
     

        <div class="col-12 px-3">
            <p>AIC collaborated with multiple stakeholders including MOH and ST Logistics to coordinate the distribution of PPE to the PHPCs. An online platform was set up to support the biweekly ordering process of PPE supplies. 
            </p>
            <p>
            AIC also organised mask-fitting for GPs and their clinic staff to ensure their safety when attending to patients. Collaterals such as the SASH Information Kit and patient education posters were also produced for the clinics. These resources raised awareness about the swabbing process to their patients. 
            </p>

        </div>
        <div class="col-12">
            <div class="table-diagram">
                <div class="row line">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_19.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                         <span class='stats'>30 </span> delivery runs of PPE to over <span class='stats'>900</span> PHPCs have been completed.
                         
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_20.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                        As of November 2020, over <span class='stats'>2,000</span> GPs <br>and clinic staff were mask-fitted over <span class='stats'> 5 </span> rounds. 
                    </div>
                </div>
            </div>
        </div>
    </section>



    <section class="content-container" id="SupportingCommunityTesting" name='Supporting Community Testing'>
        <div class="titles">
            <h4>Supporting Community Testing & Vaccination</h4>
        </div>
   
        <div class="col-12 secondary-image-container">
            <div class="img-line">
                <img src="@/assets/covid/diagram_21_1.png" alt="">
                <p class="credits">Credit: Caroline Chia</p>
            </div>
            <p class="caption">Dr Teo Boon See, a GP at Camry Medical Centre, using a locally developed booth that provides a barrier between healthcare workers and patients during swab tests </p>                
        </div> 

        <div class="col-12">
            <p>
                The PHPCs were activated for the Swab And Send Home (SASH) and Antigen
                Rapid Testing (ART) programmes to strengthen active case detection in the
                community. PHPCs also conducted swab tests for persons under quarantine and pre-departure passengers to facilitate the opening of our borders. We supported the SASH PHPCs with training, operational resources and in data reporting using the Patient Risk Profile Portal.                
            </p>
            <p>
                In May 2020, GP clinics from the <a href='/support-seniors#PrimaryCareNetworks'>Primary Care Networks</a>
                
                partnered MOH and the Ministry of Manpower to set up
                Regional Medical Centres to provide medical care for migrant workers and
                dormitories.
            </p>
            <p>
               When Singapore launched the nationwide COVID-19 vaccination drive in January 2021, PHPCs from 10 PCNs supported this initiative. AIC and PCN
                headquarters supported the clinics’ operations, equipping them with
                emergency resuscitation equipment and the GPConnect system, as well as provisioning them with the vaccines. 
            </p>
        </div>



        <div class="col-12">
         <div class="table-diagram">
            <div class="row line">
                <div class="col-4 diagram-img">
                    <img src="@/assets/covid/infographics/CovidSector_Infographics_21.png" alt="">
                </div>
                <div class="col-8 text-2">
                     <p>Over <span class="stats">600</span> PHPCs were recruited to perform onsite swabs.</p>
                </div>
            </div>

            <div class="row line">
                <div class="col-4 diagram-img">
                    <img src="@/assets/covid/infographics/CovidSector_Infographics_22.png" alt="">
                </div>
                <div class="col-8 text-2">
                    <p class='line'>Over <span class="stats">14,000</span> patients with ARI symptoms were swabbed per week in December 2020.</p>
                    
                    <p>
                        <span class="stats">400</span> persons under quarantine have been swabbed at more than <span class="stats">100</span> selected clinics. 
                    </p>
                </div>
            </div>

            <div class="row ">
                <div class="col-4 diagram-img">
                    <img src="@/assets/covid/infographics/CovidSector_Infographics_23.png" alt="">
                </div>
                <div class="col-8 text-2">
                     <p>Over <span class="stats">14,000</span> doses of vaccines have been administered by 22 PHPCs.
                    </p>
                </div>
            </div>            
        </div>
      </div>
    </section>
    </div>
</template>

<script>
export default {
    name: 'DetectingIsolating',
}
</script>
