<template>
    <section class="content-container row justify-content-center" id='Description' name='Support for Our Sector'>
        <div class="col-12 px-3">
            <p>COVID-19 brought about unprecedented challenges. 
                For seniors, our focus was on ensuring the continuity of care services for them and reaching out to and caring for seniors in the community. For our Community Care partners, we worked closely with them to put in place measures and safeguards to keep COVID-19 out as far as possible, limit its spread when cases occurred, and to recover quickly afterwards. Organisationally, we kept our functions going and staff engaged to continue fighting COVID-19 with our partners.

            </p>
        </div>
        <div class="col-12 secondary-img-container">
            <iframe width="100%" height="500" src="https://www.youtube.com/embed/1yaGU1ZT4uY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Description',
}
</script>
