var level_3_0 = {
    "0" : {
      "name": "Sector Communications",
      "hash": "SectorCommunications"
    },
    "1": {
      "name": "Infection Prevention & Control (IPC)",
      "hash": "InfectionControlPrevention"
    },
    "2": {
      "name": "Donations & Sponsorships",
      "hash": "DonationsSponsorships"
    },
    "3": {
      "name": "Going Digital",
      "hash": "GoingDigital"
    },
    "4": {
      "name": "Nursing Home Staff Accomodation",
      "hash": "NursingStaff"
    },
    "5": {
      "name": "Manpower Support",
      "hash": "ManPowerSupport"
    },

    "6": {
      "name": "COVID-19 Readiness Assessment",
      "hash": "CovidReadinessAssessment"
    },
    "7": {
      "name": "COVID-19 Incident Response Team (CIRT)",
      "hash": "Cirt"
    }
};


var level_3_1 ={
    "0": {
      "name": "Public Health Preparedness Clinics (PHPC)",
      "hash": "BolsteringPrimaryCare"
    },
    "1": {
      "name": "Resources for PHPCs",
      "hash": "PHPCs"
    },
    "2": {
      "name": "Supporting Community Testing",
      "hash": "SupportingCommunityTesting"
    },
    '3': {
      "name": "Communication & Engagement",
      'hash': 'CommunicationsEngagement'
    }    
}

var level_2_0 = {
  '0' : {
    name : 'Supporting Business Continuity',
    hash : 'CommunityCare',
    links : level_3_0 
  },
  '1' : {
    name: "Facilitating Testing & Vaccination",
    hash: 'Facilitating',
    links: []
  },  
}

var level_2_1 = {
  '0' : {
    name : '',
    hash : 'BolsteringPrimaryCare',
    links : level_3_1
  }
}




var level_1 = {
  '0' : {
    name: 'Sustaining Community Care',
    sections: level_2_0,
    active: false
  },

  '1' : {
    name: 'Bolstering Primary Care',
    sections: level_2_1,
    active:false
  }

}

export default level_1; 