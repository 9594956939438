<template>
	<section class="content-container" id="Facilitating" name='Facilitating Testing &amp; Vaccination'>
        <div class="titles">
            <h4>Facilitating Testing & Vaccination</h4>
        </div>

        <div class="col-12 secondary-image-container">
           <div class="img-line">
            <img src="@/assets/covid/diagram_17_1.png" alt="">
            <p class="credits">Credit: Ren Ci Hospital</p>
            </div>
            <p class="caption">A resident at Ren Ci @ Bukit Batok St. 52 receiving the first dose of a COVID-19 vaccination from a trained nurse</p>    
        </div>

        <div class="col-12 px-3">
            <p>AIC worked closely with MOH, the National Public Health Laboratory (NPHL) and Community Care partners to conduct mass surveillance testing for care staff, clients and residents. With NPHL’s support, AIC facilitated the roll-out of pooled testing to increase efficiency and facilitate faster test results. We also tried out alternative methods to nasopharyngeal swabbing to reduce discomfort and operational impact on partners.<br><br>
                Starting in December 2020, AIC worked with MOH and the Health Promotion Board to coordinate the vaccination of Community Care staff, clients and residents. Partners could select from in-situ vaccinations, deployment of mobile vaccination teams to their premises, or sending staff to vaccination sites.</p>
        </div>
        <div class="col-12">
            <div class="table-diagram">
                <div class="row line">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_15.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                         <p> <span class="stats">100%</span>  of Community Care staff and clients have been tested.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_16.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                        <p>Over <span class="stats">90%</span> of Community Care staff has been vaccinated.</p>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
export default {
    name: 'Facilitating',
}
</script>
