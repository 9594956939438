<template>
	<section class="content-container" id="DonationsSponsorships" name='Donations & Sponsorships'>
        <div class="titles">
            <h5>Donations & Sponsorships</h5>
        </div>
        <div class="col-12 secondary-image-container">
                <img src="@/assets/covid/diagram_5_1.png" alt="">
                <p class="caption">Contactless thermometers were among the in-kind items donated by corporate partners</p>
        </div>
        <div class="col-12 px-3">
            <p>Besides PPE, Community Care partners needed cash and in-kind support in their fight against COVID-19. To facilitate access to the outpouring of support from individuals and corporations, we stepped in to manage the donations and sponsorships, setting up three funding initiatives:<br><br>
                <strong>#StrongerThanBefore:</strong> Supported partners affected by cases of COVID-19 infection within their facility so that they could return to normal operations as quickly as possible. It offset additional operational costs incurred by activities such as increased cleaning and disinfection of the premises and purchase of additional PPE. <br><br>
                <strong>#StrongerTogether:</strong>  Supported partners in showing appreciation to staff for their vigilance and contributions in keeping seniors safe. Funds could be used to cater meals and purchase care packs, food and gift vouchers for staff.

                <br><br>
                
                <strong>#ReadyTogether:</strong>  Supported partners’ efforts to enhance service continuity by adjusting the way they work, including digital initiatives and the use of robotics.</p>
        </div>
        <div class="col-12">
            <div class="table-diagram">
                <div class="row line">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_4.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                         <p>Over <span class="stats">$9 million</span>  in cash.</p>
                    </div>
                </div>
                <div class="row line">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_5.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                        <p>Over <span class="stats">110</span>  Community Care organisations benefited from the three initiatives.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_6.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                        <p>We coordinated the distribution of over <span class="stats">2 million</span>  surgical masks,  <span class="stats">2,700</span>  litres of hand sanitiser and over <span class="stats">2,800</span>  oximeters from Temasek Foundation, as well as <span class='stats'>4,500</span> face shields from a private donor.</p>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
export default {
    name: 'DonationsSponsorships',
}
</script>
