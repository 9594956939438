<template>
	<section class="content-container" id="ManPowerSupport" name='Manpower Support'>
        <div class="titles">
            <h5>Manpower Support</h5>
        </div>
        <div class="col-12 secondary-image-container">
             <div class="img-line">
                <img src="@/assets/covid/diagram_11_1.png" alt="">
                <p class="credits">Credit: All Saints Home (ASH)</p>
                </div>
                <p class="caption">Singapore Airlines staff deployed to All Saints Home as Care Ambassadors</p>   
        </div>

        <div class="col-12 px-3">
            <p>AIC also supported the stretched sector with other manpower initiatives. We worked with Community Care partners such as Peacehaven Nursing Home, St. Andrew’s Nursing Home and Kwong Wai Shiu Hospital to build a step-in contingency workforce for deployment to nursing homes in the event of staff quarantine due to COVID-19 infection.<br><br>
                We scaled up recruitment outreach with SGUnited Jobs and leveraged SG Healthcare Corps to bring in individuals as Community Care Ambassadors or healthcare assistants. We worked with Peacehaven Nursing Home and HMI Institute of Health Sciences to conduct a five-day training programme for these new joiners before deployment to nursing homes or care centres. <br><br>
                AIC also worked with Singapore Airlines and Jetstar Asia to redeploy aviation staff to Community Care organisations under the Care Ambassador Programme. This initiative has  helped partners cope with their manpower needs while providing temporary employment during COVID-19. </p>
        </div>
        <div class="col-12">
            <div class="table-diagram">
                <div class="row line">
                    <div class="col-4 diagram-img">
                        <img  src="@/assets/covid/infographics/CovidSector_Infographics_10.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                         <p>A step-in team of <span class="stats">24</span> staff was deployed to Lee Ah Mooi Old Age Home from 1 to 9 September 2020 when a former staff member tested positive for COVID-19.</p>
                    </div>
                </div>
                <div class="row line">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_11.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                        <p>There were more than <span class="stats">7,000</span> sign-ups from SG Healthcare Corps. </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_12.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                        <p>Since July 2020, almost <span class="stats">200</span> aviation  staff <br>have been redeployed within the care sector.</p>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
export default {
    name: 'ManpowerSupport',
}
</script>
