<template>
    <div>
    <section id='CommunityCare' class="content-container" name='Community Care'>
        <div class="titles">
            <h2>Sustaining Community Care</h2>
            <h4>Supporting Business Continuity</h4>
            
        </div>
    </section>

    <section id='SectorCommunications' class="content-container" name='Section Communications'>
        <div class="col-12 px-3">
            <h5>Sector Communications</h5>
            <p>AIC served as the central communication node of the Community Care Sector as we stepped up COVID-19 precautions. We issued advisories together with Ministry of Health (MOH), addressed information gaps and even misinformation in the early phases, and tapped on various communication channels to facilitate the timely flow of information. <br><br>
            By working with MOH, public health and infectious diseases experts, AIC provided guidelines for COVID-19 precautions and tailored them to providers’ feedback and constraints. We also conducted webinars to equip partners with information and resources to roll out precautionary measures, while supporting their clients physically, emotionally and clinically in the process.</p>
        </div>

        <div class="col-12">
            <div class="table-diagram">
                <div class="row line">
                    <div class="col-4 diagram-img">
                        <img class="" src="@/assets/covid/infographics/CovidSector_Infographics_1.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                         <p>Over <span class="stats">70</span> advisories and updates to the sector have been issued since January 2020.</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_2.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                         <p>Close to <span class="stats">30</span>  webinars have been conducted.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>

<script>
export default {
    name: 'CommunityCare',
}
</script>
