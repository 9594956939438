<template>
	<section class="content-container" id="NursingStaff" name='Nursing Staff'>
        <div class="titles">
            <h5>Nursing Home Staff Accommodation</h5>
        </div>


        <div class="col-12 secondary-image-container">
            <div class="img-line">
                <img src="@/assets/covid/diagram_9_1.png" alt="">
                <p class="credits">Credit: Sree Narayana Mission (Singapore)</p>
            </div>
            <p class="caption">In May 2020, nursing homes had to convert available spaces into staff accommodation or house staff in hotels</p>
        </div>

        <div class="col-12 px-3">
            <p>Movement restrictions such as split zones had a major impact on staff at nursing homes. In May 2020, MOH announced that resident-facing staff would need to move into designated accommodation facilities at nursing homes or to hotels as a temporary measure to reduce their exposure to the community. MOH further established new guidelines for nursing home staff accommodation later that month.<br><br>
                To support partners in implementing these guidelines, AIC worked with MOH to provide support in terms of funding, housing and transportation.</p>
        </div>
        <div class="col-12">
            <div class="table-diagram">
                <div class="row">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_9.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                         <p>Over <span class="stats">7,500</span>  resident-facing staff were supported. </p>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
export default {
    name: 'NursingStaff',
}
</script>
