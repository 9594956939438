<template>
	<section id='GoingDigital' class="content-container" name='Going Digital'>
        <div class="titles">
            <h5>Going Digital</h5>
        </div>
        <div class="col-12">
                <img src="@/assets/covid/diagram_7_1.png" alt="">
                <p class="caption">Service providers turned to video consultations to ensure continuity of care for their clients amidst pandemic restrictions</p>
            
        </div>
        <div class="col-12 px-3">
            <p>With movement restrictions in effect, our partners turned to the digital medium to complement their services. In March 2020, MOH approved the use of video consultations for pre-approved cases and services. AIC supported our partners in liaising with the authorities as they put up the necessary applications to MOH.<br><br>
                In May 2020, we started a fund for partners to buy laptops, tablets, smartphones and data plans for staff and clients. We also distributed repurposed tablets to nursing homes for residents to remain connected with friends and family through video calls.<br><br>
                With this support, Community Care partners could continue to check in on seniors and support their care needs. Video calls also allowed care staff, volunteers and befrienders to engage seniors and keep them meaningfully occupied through virtual exercise sessions, cooking demonstrations, arts and crafts, and sing-alongs.</p>
        </div>
        <div class="col-12">
            <div class="table-diagram">
                <div class="row line">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_7.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                        <p>Over <span class="stats">20</span> Community Care partners have adopted teleconsultation to engage their clients, benefiting over <span class="stats">200</span> unique clients monthly.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_8.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                        <p>Our Video Consultation Infrastructure Fund supported <span class="stats">30</span>  Community Care partners, <br>benefiting over <span class="stats">1,300</span> clients.</p>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
export default {
    name: 'GoingDigital',
}
</script>
