<template>
	<section class="content-container" id="BolsteringPrimaryCare" name='Bolstering Primary Care'>
        <div class="titles">
            <h2>Bolstering Primary Care</h2>
            <p>Besides the Community Care sector, AIC also played a role in supporting general practitioners (GPs) during the pandemic.</p>
            <h4>Public Health Preparedness Clinics (PHPC)</h4>
        </div>

        <div class="col-12 secondary-image-container">
            <img src="@/assets/covid/diagram_19_1.png" alt="">
                <p class="caption">One of the many GP clinics that was activated as a PHPC during the pandemic</p>        
        </div>
 
        <div class="col-12 px-3">
            <p>
               AIC manages the PHPC scheme, which mobilises primary care clinics to support and manage public health emergencies. 
            </p>

            <p>
                The PHPCs were activated since February 2020 for the Flu Subsidy Scheme (FSS) to provide subsidised treatment for acute respiratory infection (ARI) symptoms.
            </p>
            <p>
              AIC supported the operationalisation of the PHPCs. We engaged the clinics, conducted briefing sessions and issued advisories with MOH and refined guidelines as the situation evolved. A Patient Risk Profile Portal was also set up to support GPs in triaging patients.  
            </p>

        </div>
        <div class="col-12">
            <div class="table-diagram">
                <div class="row line">
                    <div class="col-4 diagram-img">
                        <img  src="@/assets/covid/infographics/CovidSector_Infographics_17.png" alt="">
                    </div>
                    <div class="col-8 text-2 text-dark px-2 align-self-center">
                         Over <span class='stats'>1,000</span> GP clinics have come onboard the PHPC scheme. <span class='stats'>990</span> were activated to provide the FSS.
                    </div>
                </div>
                <div class="row line">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/covid/infographics/CovidSector_Infographics_18.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                        <p>Over <span class="stats">700,000</span> patients with ARI symptoms have been seen at PHPCs. </p>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
export default {
    name: 'BolsteringPrimaryCare',
}
</script>
