<template>
	<div id="covid-sector" :class='fontsize'>
    
		<Intro :intro="intro"/>

		<div class="container-fluid secondary-section" id="secondary-content">
			<div class="row fontsize">
  			 
  			  <SubNavOne :navLinks='navLinks'/>

			    <section id='Navigation' class="content ml-auto">

					<Description/>					
					
					<CommunityCare/>

					<InfectionControlPrevention/>

					<DonationsSponsorships/>

					<GoingDigital/>

					<NursingStaff/>

					<ManpowerSupport/>

					<CovidReadinessAssessment/>

					<Cirt/>

					<Facilitating/>

					<BolsteringPrimaryCare/>

					<DetectingIsolating/>

					<CommunicationsEngagement/>  

					</section>
				<PageNavigation :prev="'/'" :next="'/support-public'" />
			</div>
		</div>
	</div>	
</template>

<script>
// @ is an alias to /src
import Subnavigation from '@/components/covid/Subnavigation.vue'
import SubNavOne from '@/components/SubNavOne.vue'
import NavLinks from '@/components/covid/navLinks.js'

import Intro from '@/components/Intro.vue'
import Description from '@/components/covid/Description.vue'
import CommunityCare from '@/components/covid/CommunityCare.vue'
import InfectionControlPrevention from '@/components/covid/InfectionControlPrevention.vue'
import DonationsSponsorships from '@/components/covid/DonationsSponsorships.vue'
import GoingDigital from '@/components/covid/GoingDigital.vue'
import NursingStaff from '@/components/covid/NursingStaff.vue'
import ManpowerSupport from '@/components/covid/ManpowerSupport.vue'
import CovidReadinessAssessment from '@/components/covid/CovidReadinessAssessment.vue'
import Cirt from '@/components/covid/Cirt.vue'
import Facilitating from '@/components/covid/Facilitating.vue'
import BolsteringPrimaryCare from '@/components/covid/BolsteringPrimaryCare.vue'
import DetectingIsolating from '@/components/covid/DetectingIsolating.vue'
import CommunicationsEngagement from '@/components/covid/CommunicationsEngagement.vue'
import PageNavigation from '@/components/PageNavigation.vue'

import dynamicFontSizeMixin from '@/mixin/dynamicFontSizeMixin'

export default {
		name: 'Covid',

    data() {
      return {
        scroll: 0,
        sections: '',
        currentSec: '',
        navLinks : NavLinks,
        hashes: [
          'community-care',
          'going-digital'
        ],
		intro: {
			title: 'Sustaining care through adversity',
			secondTitle: 'Support for our sector',
			credit: "Credit: St. Andrew’s Community Hospital",
			img: this.mobileAndTabletCheck() ? require("@/assets/covid/CovidSector_Desktop_Banner.jpg") : require("@/assets/covid/CovidSector_Desktop_Banner.jpg")
		}
      }
    },
		mounted() {
			// 	this.sections = this.getSections()
			// 	console.log(JSON.stringify(this.sections))
			// 	window.addEventListener("scroll", this.onScroll)

		},

		mixins: [dynamicFontSizeMixin],

		components: {
			Subnavigation,
			SubNavOne,
			Intro,
			Description,
			CommunityCare,
			InfectionControlPrevention,
			DonationsSponsorships,
			GoingDigital,
			NursingStaff,
			ManpowerSupport,
			CovidReadinessAssessment,
			Cirt,
			Facilitating,
			BolsteringPrimaryCare,
			DetectingIsolating,
			CommunicationsEngagement,
			PageNavigation
		},
}
</script>

<style scoped>
  .link {
    color: #000;
  }

	.btn{
		border-radius: 5px;
		width: 100px;
		height: 30px;
		position: relative;
	}
	.btn span{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 12px;
	}

</style>

<style>


.heading-3{
	font-size: 8vw;
}

.heading-5{
	position: absolute;
	bottom: 0;
	font-style: italic;
	color: white;
	font-size: 2.8vw;
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
}

.heading-6{
	font-size: 7vw;
}


.line{
	position: relative;
}


.img-line{
	position: relative;
}

.btn span {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 480px){	
	.intro-img{
		height:175px;
	}
}

 /* media */
 @media (min-width: 768px) {
	 .heading-3{
		 font-size: 50px;
	 }
	 .heading-5{
		 font-size: 13px;
	 }
	 .heading-6{
		 font-size: 42px;
	 }

	 .text-2{
		 font-size: 16px;
	 }
	 .text-2 span{
		 font-size: 22px;
	 }
	 .img-block{
		 width: 45%;
		 padding-bottom: 60px;
	 }
	.content{
	 	width: 70vw;
	}


}
</style>
